import React from 'react'
import { Outlet } from 'react-router-dom'
import { Stack } from '@mui/material'

import { Header, Footer } from 'components'

export const PageLayout = () => {
  return (
    <Stack>
      <Header />

      <Outlet />

      <Footer />
    </Stack>
  )
}
