import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { Card } from './Card'

import Recs from 'assets/images/recs.png'
import Map from 'assets/images/map.png'
import People from 'assets/images/people.png'

const cards = [
  {
    title: 'Create Your Food Journal',
    text: 'Track your favorite dishes and create a personalized collection to share',
    image: Recs,
    maxWidth: 400,
  },
  {
    title: 'Share your Recommendations',
    text: 'Let your friends know where to try something delicious',
    image: People,
    maxWidth: 400,
  },
]

const lastCard = {
  title: 'Dish Recommendations Around You',
  text: 'Get personalized dish recommendations based on your location and food preferences',
  image: Map,
  maxWidth: 250,
}

export const Solution = () => {
  return (
    <Box m={{ xs: 2, md: 4 }} p={{ md: 6 }}>
      <Typography variant="h2" maxWidth={800} margin="0 auto" textAlign="center">
        One solution for multiple problems
      </Typography>
      <Box mt={8}>
        <Stack gap={4} alignItems="center">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'center', md: 'stretch' }}
            gap={4}
            justifyContent="center"
          >
            {cards.map((props) => (
              <Card key={props.text} {...props} />
            ))}
          </Stack>
          <Card {...lastCard} />
        </Stack>
      </Box>
    </Box>
  )
}
