import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import QuestionIcon from 'assets/images/question.png'

const problemsList = [
  `Want to visit a new restaurant but don't know where to go?`,
  `Want to try a new dish but can't choose anything?`,
  `Your friend recommended a good place to eat, but you didn't like it at all?`,
]

export const Problems = () => {
  return (
    <Box overflow="hidden">
      <Box m={{ xs: 2, md: 4 }} p={{ md: 6 }}>
        <Box mt={8}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            maxWidth={1160}
            margin="0 auto"
            gap={4}
          >
            <Box
              flex={0.5}
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems={{ xs: 'center', md: 'start' }}
              textAlign={{ xs: 'center', md: 'left' }}
            >
              <Typography variant="h2" color="#F23D3D">
                What Problems Do We Solve
              </Typography>
              <Typography variant="h3" maxWidth={480}>
                Mealjoy is a mobile application that helps to improve your taste experience in
                restaurants and cafes
              </Typography>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <Stack flex={0.5} gap={4} mt={4}>
                  {problemsList.map((problem, index) => (
                    <motion.div
                      key={problem}
                      variants={{
                        offscreen: {
                          y: 300,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            type: 'spring',
                            bounce: 0.2,
                            duration: 1,
                            opacity: { duration: 1, delay: index * 0.5 },
                            delay: index * 0.5,
                          },
                        },
                      }}
                    >
                      <Box
                        key={problem}
                        gap={2}
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                      >
                        <Box
                          textAlign="center"
                          alignContent="center"
                          sx={{
                            borderRadius: '50%',
                            border: '2px solid #F23D3D',
                            width: 60,
                            height: 60,
                            minWidth: 60,
                            background: '#FEEEEE',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="#F23D3D"
                            fontSize={20}
                            fontWeight={600}
                          >
                            {index + 1}
                          </Typography>
                        </Box>
                        <Typography fontSize={18}>{problem}</Typography>
                      </Box>
                    </motion.div>
                  ))}
                </Stack>
              </motion.div>
            </Box>
            <Box flex={0.5}>
              <img src={QuestionIcon} style={{ maxWidth: 600, width: '100%' }} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
