import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion'

import Phone from 'assets/images/Mockup.png'
import App_Store_Button from '../assets/images/app_store_button.svg'
import { Analytics } from 'analytics'

const cardVariants = {
  offscreen: {
    x: '-100%',
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 1,
      opacity: { duration: 1 },
    },
  },
}

const cardVariants2 = {
  offscreen: {
    x: '100%',
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 1.5,
      opacity: { duration: 1.5 },
    },
  },
}

export const Main = () => {
  const handleDownloadClick = () => {
    Analytics.clickDownloadButton()
  }

  // const handleRequestDemoClick = () => {
  //   Analytics.clickRequestDemo()
  // }

  return (
    <Box m={{ xs: 2, md: 4 }} p={{ md: 6 }} overflow="hidden">
      <Stack direction={{ sm: 'column', md: 'row' }} alignItems="center" gap={4}>
        <Box flex={0.5} textAlign="center">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div variants={cardVariants}>
              <img src={Phone} style={{ maxWidth: 700, minWidth: 400, width: '100%' }} />
            </motion.div>
          </motion.div>
        </Box>
        <Box flex={0.5}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div variants={cardVariants2}>
              <Stack
                direction="column"
                alignItems={{ xs: 'center', md: 'start' }}
                textAlign={{ xs: 'center', md: 'start' }}
                gap={4}
              >
                <Typography sx={{ typography: { xs: 'h2', md: 'h2', lg: 'h1' } }}>
                  Discover & Share Delicious Dish{' '}
                  <strong style={{ color: '#F23D3D' }}>Recommendations</strong> Near You!
                </Typography>

                <Typography fontSize={{ xs: 20, md: 20, lg: 24 }} fontWeight={500} color="#5a5d67">
                  Choosing a dish from menu should be a delightful experience, not a gamble
                </Typography>

                <Stack gap={1.5} alignItems={{ xs: 'center', md: 'start' }}>
                  <Typography color="#F23D3D">Available to Download on</Typography>

                  <Link
                    component="button"
                    onClick={() => {
                      handleDownloadClick()
                      window.open(
                        'https://apps.apple.com/us/app/mealjoy-food-recommendation/id6504121432',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }}
                  >
                    <img src={App_Store_Button} alt="" width={160} />
                  </Link>
                </Stack>

                <Typography color="#5a5d67" fontSize={{ lg: 16 }}>
                  Join thousands of food enthusiasts who can&apos;t wait to share their favorite
                  dishes!
                </Typography>
              </Stack>
            </motion.div>
          </motion.div>
        </Box>
      </Stack>
    </Box>
  )
}
