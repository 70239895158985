import React from 'react'
import { Box, Stack, Typography, Link } from '@mui/material'

import { Analytics } from 'analytics'
import cta from 'assets/images/cta.png'
import App_Store_Button from 'assets/images/app_store_button.svg'

export const CallToAction = () => {
  const handleDownloadClick = () => {
    Analytics.clickDownloadButton()
  }

  return (
    <Box m={{ xs: 2, md: 4 }} p={{ md: 6 }}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1100}
        margin="0 auto"
        gap={4}
      >
        <Box flex={0.5}>
          <img src={cta} style={{ maxWidth: 600, minWidth: 400, width: '100%' }} />
        </Box>
        <Stack flex={0.5} gap={4} textAlign="center" alignItems="center">
          <Typography variant="h2" color="#F23D3D">
            Ready to find your next favorite meal?
          </Typography>
          <Typography fontSize={24} color="#5a5d67">
            Join our community today and start recommending, reviewing, and savoring delicious
            dishes from your favorite menus. Your next food adventure is just a click away!
          </Typography>

          <Stack direction={{ xs: 'column', sm: 'row' }} gap={1.5}>
            <Link
              component="button"
              onClick={() => {
                handleDownloadClick()
                window.open(
                  'https://apps.apple.com/us/app/mealjoy-food-recommendation/id6504121432',
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
            >
              <img src={App_Store_Button} alt="" width={160} />
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
